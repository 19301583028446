
import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import signupback from '../images/signupback.jpg'

    

const Career = () => {
    
    // React.useEffect(() => {
    //     var qs,js,q,s,d=document, 
    //     gi=d.getElementById, 
    //     ce=d.createElement, 
    //     gt=d.getElementsByTagName, 
    //     id="typef_orm", 
    //     b="https://embed.typeform.com/"; 
    // if(!gi.call(d,id)) { 
    //   js=ce.call(d,"script"); 
    //   js.id=id; 
    //   js.src=b+"embed.js"; 
    // q=gt.call(d,"script")[0]; 
    // q.parentNode.insertBefore(js,q) 
    // }
    // },[])

  return ( <Layout>
   <div class="inner-page">
   <div class="slider-item overlay bg-overly " style={{backgroundImage:`url(${signupback})`}} >
    {/* <div class="slider-item " style="background-image: url('');"> */}
        <div class="container">
        <div class="flex flex-row slider-text items-center justify-center" style={{height:450}}>
        {/* <div class="flex-col items-center justify-center text-center col-sm-12 element-animate"> */}
          <h1 class="self-center text-white text-5xl">CAREERS</h1>
        {/* </div> */}
      </div>
            
        </div>
    </div>
</div>
<div class="flex mx-4 md:px-8 md:mx-8" style={{height: 600}}>
    <div class="flex w-full  my-5">
           
    {/* <div class="typeform-widget w-full" data-url="https://form.typeform.com/to/HhiSgu5r?typeform-medium=embed-snippet" style={{width: '100%', height: 500}}> */}
    <iframe id="typeform-full" className="h-full w-full" frameBorder={0} allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/HhiSgu5r?typeform-medium=embed-snippet"></iframe>
    {/* </div> */}

    </div>
</div>
    
  </Layout>)
}

export default Career




